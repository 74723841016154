<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <header-wrapper class="section hero-section">
        <div class="wrapper">
          <div class="content">
            <div class="title big">Crypto in Your Pocket!</div>
            <div class="desc">
              Your Trusted Partner for Crypto-Fiat Exchange
            </div>
            <div @click="checkAuth" class="button get-started-btn">
              Get started
            </div>
          </div>
          <img
            class="payment-img"
            src="../images/3d_payment_deadline.svg"
            alt=""
          />
          <!--          <div class="title big">Your Ultimate Crypto-Fiat Exchange Platform!</div>-->
          <!--          <div class="desc">Experience the simplicity and convenience of Bitpooh today, and make your crypto-fiat exchange process smoother than ever before!</div>-->
          <!--          <div @click="checkAuth" class="button" >Get Started</div>-->
          <!--          <ul class="list features">-->
          <!--            <li class="item">-->
          <!--              <img src="./../images/security.svg" class="img"/>-->
          <!--              <div class="title small">Bank-grade security</div>-->
          <!--              <div class="desc">We are more than a bank. We are the full-blown safe bay</div>-->
          <!--            </li>-->
          <!--            <li class="item">-->
          <!--              <img src="./../images/stopwatch.svg" class="img"/>-->
          <!--              <div class="title small">Quick KYC</div>-->
          <!--              <div class="desc">Our team does everything it can to open a world of possibilities in crypto scop for you</div>-->
          <!--            </li>-->
          <!--            <li class="item">-->
          <!--              <img src="./../images/customer-service.svg" class="img"/>-->
          <!--              <div class="title small">Outstanding tech support</div>-->
          <!--              <div class="desc">Enjoy the dream tech team service you always wanted</div>-->
          <!--            </li>-->
          <!--          </ul>-->
        </div>
      </header-wrapper>
      <div class="section section-2">
        <div class="wrapper">
          <div class="title">How it works</div>
          <div class="step-list">
            <div
              v-for="({ title, desc }, index) in howItWorksSteps"
              :key="index"
              :class="{ odd: !((index + 1) % 2 === 0) }"
              class="step-list__item"
            >
              <div class="step__index">
                {{ index + 1 }}
              </div>
              <div class="step__title">
                {{ title }}
              </div>
              <div class="step__desc">
                {{ desc }}
              </div>
            </div>
          </div>
          <!--          <div class="widget">-->
          <!--            <div id="tradingview-widget"></div>-->
          <!--          </div>-->
        </div>
      </div>

      <div class="section section-3">
        <div class="wrapper">
          <div class="columns">
            <div class="column">
              <div class="title">Benefits of using Bitpooh</div>
            </div>
            <div class="column">
              <div class="benefits">
                <div
                  v-for="({ icon, title, desc }, index) in benefits"
                  :key="index"
                  :class="{ odd: !((index + 1) % 2 === 0) }"
                  class="benefits__item"
                >
                  <div class="icon-wrapper">
                    <img :src="getImageUrl(icon)" alt="" />
                  </div>
                  <div class="text">
                    <div class="text__title">
                      {{ title }}
                    </div>
                    <div class="text__desc">
                      {{ desc }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section section-5">
        <div class="wrapper">
          <div class="title big">Online crypto exchange</div>
          <div class="card-list">
            <div class="card-list__item well">
              <div class="list" v-if="exchangeList.length">
                <div class="column__currency">
                  <template v-for="item in exchangeListUpdated">
                    <div :key="item.crypto_currency" class="row">
                      <div class="desc">
                        <img
                          :src="getImageUrl(`${item.crypto_currency}.svg`)"
                          alt=""
                        />
                      </div>
                      <div class="desc">{{ item.title }}</div>
                      <div class="desc">{{ item.crypto_currency }}</div>
                    </div>
                  </template>
                </div>
                <div class="column__price">
                  <template v-for="item in exchangeListUpdated">
                    <div :key="item.crypto_currency" class="row">
                      € {{ item.price }}
                    </div>
                  </template>
                </div>
                <div class="column__growth">
                  <template v-for="item in exchangeListUpdated">
                    <div :key="item.crypto_currency" class="row">
                      + {{ item.coeff }}%
                    </div>
                  </template>
                </div>
                <!--                <div class="item" v-for="item in exchangeListUpdated" :key="item.id">-->
                <!--                  <div class="left">-->
                <!--                    <div class="desc"><img :src="getImageUrl(`${item.crypto_currency}.svg`)" alt=""></div>-->
                <!--                    <div class="desc">{{item.title}}</div>-->
                <!--                    <div class="desc">{{item.crypto_currency}}</div>-->
                <!--                  </div>-->
                <!--                  <div class="desc">€ {{item.price}}</div>-->
                <!--                  <div class="desc">+ {{item.coeff}}%</div>-->
                <!--                </div>-->
              </div>
            </div>
            <div :class="{ crypto: !fiatToCrypto }" class="card-list__item buy">
              <div class="head">
                <div class="title" v-if="!fiatToCrypto">
                  Exchange crypto to fiat
                </div>
                <div class="title" v-if="fiatToCrypto">
                  Exchange fiat to crypto
                </div>
                <div class="exchange-container">
                  <ExchangeButton @click="fiatToCrypto = !fiatToCrypto" />
                </div>
              </div>
              <div class="inputs">
                <select
                  class="currency-select"
                  v-if="exchangeList.length"
                  v-model="chosenCrypto"
                  @change="changeTakeAmount(giveAmount)"
                >
                  <option
                    v-for="item in exchangeListUpdated"
                    :key="item.id"
                    :value="item"
                  >
                    {{ item.title }}
                  </option>
                </select>
                <div class="input-container">
                  <input
                    type="number"
                    v-model="giveAmount"
                    @input="changeTakeAmount(giveAmount)"
                  />
                  <div class="desc">EUR</div>
                </div>
              </div>
              <div class="total">
                <div class="desc">Total:</div>
                <div class="desc">{{ total }}</div>
              </div>
              <button
                class="button"
                @click="
                  $parent.toExchange(giveAmount, takeAmount, chosenCrypto)
                "
              >
                Buy Crypto
              </button>
            </div>
          </div>
          <div class="widget">
            <div id="tradingview-widget"></div>
          </div>
        </div>
      </div>

      <div class="section section-6">
        <div class="wrapper">
          <ContactForm />
        </div>
      </div>

    <FaqSection v-if="$parent.faq && $parent.faq.length" :faq="$parent.faq"/>
      <!--      <div class="section section-4">-->
      <!--        <div class="wrapper">-->
      <!--          <ContactForm/>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </main>
</template>
<script>
// import ContactForm from "@/components/ContactForm.vue";
// import ExchangeButton from "@/components/ExchangeButton.vue";
import HeaderWrapper from "@/components/HeaderWrapper.vue";
import ExchangeButton from "@/components/ExchangeButton.vue";
import ContactForm from "@/components/ContactForm.vue";
import FaqSection from "../components/FaqSection.vue";

export default {
  name: "Home",
  props: [],
  components: {
    ContactForm,
    ExchangeButton,
    HeaderWrapper,
    FaqSection
    // ExchangeButton,
    // ContactForm
  },
  data: function () {
    return {
      exchangeList: [],
      giveAmount: 100,
      takeAmount: 0,
      chosenCrypto: {},
      fiatToCrypto: true,
      howItWorksSteps: [
        {
          title: "Sign in",
          desc: "Create an account and complete the verification process. Once your account is verified, you're ready to start trading.",
        },
        {
          title: "Connect your wallet",
          desc: "Select your preferred cryptocurrency wallet from the available options and follow the on-screen instructions to securely sync your wallet.",
        },
        {
          title: "Make a payment",
          desc: "Select the type of transaction, choose the crypto or fiat currency you want to exchange, specify the amount, and review the transaction details carefully. Confirm the transaction.",
        },
      ],
      benefits: [
        {
          title: "Enhanced Security and Reliability",
          desc: "We prioritise the security of your funds and transactions, employing advanced security protocols and multi-level safeguards.",
          icon: "shield.svg",
        },
        {
          title: "Effortless Crypto-Fiat Conversion",
          desc: "Our user-friendly interface and intuitive design make it simple to exchange.",
          icon: "time.svg",
        },
        {
          title: "Competitive Rates and Low Fees",
          desc: "We offer competitive rates for crypto-fiat exchanges and maintain transparent fee structures that allow you to make informed decisions.",
          icon: "folder.svg",
        },
        {
          title: "24/7 Customer Support",
          desc: "Our dedicated customer support team is available around the clock to assist you with any questions.",
          icon: "chatting.svg",
        },
      ],
      reviews: [
        {
          name: "Caleb Chang",
          text: "As a seasoned trader, I appreciate the platform's advanced features, competitive rates and low fees.",
        },
        {
          name: "Jackson Foster",
          text: "24/7 customer support is invaluable.",
        },
        {
          name: "Xavier Ramirez",
          text: "I've been using this platform for a while now, and I've never experienced any technical glitches or downtime.",
        },
      ],
    };
  },
  computed: {
    exchangeListUpdated() {
      return this.exchangeList.filter((item) => item.currency === "EUR");
    },
    total() {
      if (this.choosenCrypto && this.chosenCrypto.price) {
        if (this.fiatToCrypto) {
          return `${
            this.giveAmount / this.chosenCrypto.price
          } ${this.chosenCrypto.crypto_currency.toUpperCase()}`;
        }

        return `${this.chosenCrypto.price * this.giveAmount} EUR`;
      }

      return 0;
    },
  },
  mounted() {
    if (this.$route.query["redirect"]) {
      this.$parent.openSignInModal();
    }

    this.getExchangeList();
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/tv.js";
    script.async = true;
    script.onload = this.initializeWidget;
    document.body.appendChild(script);

    var iframe = document.getElementsByTagName("iframe")[0];

    if (iframe) {
      iframe.addEventListener("load", function () {
        /* the JS equivalent of the answer
         *
         * var node = document.createElement('style');
         * node.appendChild(document.createTextNode('body { background: #fff; }'));
         * window.frames[0].document.head.appendChild(node);
         */

        // console.log('Hudfdfd43');

        // the cleaner and simpler way
        window.frames[0].document.body.style.backgroundColor = "#fff";
      });
    }
  },
  methods: {
    checkAuth() {
      if (this.$parent.isAuth) {
        this.$router.push({ path: "/buy-crypto" });
      } else {
        this.$parent.openSignInModal();
      }
    },
    getExchangeList() {
      this.$http
        .get(process.env.VUE_APP_API + "exchange-top")
        .then((res) => {
          this.exchangeList = res.data.payload;
          this.chosenCrypto = res.data.payload[0];
          this.changeTakeAmount(this.giveAmount);
        })
        .catch(() => {});
    },
    changeTakeAmount(newValue) {
      if (this.chosenCrypto.price) {
        this.takeAmount = newValue / this.chosenCrypto.price;
      } else {
        this.takeAmount = 0;
      }
    },
    initializeWidget() {
      new window.TradingView.widget({
        autosize: true,
        symbol: "BITSTAMP:BTCUSD",
        interval: "D",
        timezone: "Etc/UTC",
        theme: "light",
        style: "2",
        locale: "en",
        gridColor: "#fff",
        toolbar_bg: "#006E79",
        enable_publishing: false,
        allow_symbol_change: true,
        container_id: "tradingview-widget",
      });
    },
    getImageUrl(img) {
      // console.log(`../images/${img}`);
      return require(`../images/${img}`);
    },
    getReviewImage(img) {
      return require(`../images/reviews/${img}`);
    },
  },
};
</script>
