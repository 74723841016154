<template>
  <div class="form-wrapper">
    <div class="header">
      <h2 class="header__text">Contact us</h2>
    </div>

    <form @submit.prevent="submit" class="contact-form" :class="{submitted: formSubmitted}">
      <template v-if="!formSubmitted">
        <div class="container">
          <div class="column">
            <input placeholder="Name *" required v-model="name" type="text">
          </div>
          <div class="column">
            <input placeholder="E-mail *" required v-model="email" type="email">
          </div>
          <div class="column" v-if="countryOptionsIso && countryOptionsIso.length">
            <VuePhoneNumberInput v-model="phone" :only-countries="countryOptionsIso" :default-country-code="countryOptionsIso[0]" @update="phoneDataUpdate($event)"/>
          </div>
          <div class="column">
            <select v-model="country">
              <option disabled selected value="">Country</option>
              <option v-for="(item, i) in countryOptions" :value="item" :key="i">{{ item.title }}</option>
            </select>
          </div>
          <div class="column">
            <textarea placeholder="Your comment *" v-model="comment" required name="comment" cols="3" rows="5"></textarea>
          </div>
        </div>
        <div class="bottom">
          <button class="button" type="submit">Submit</button>
        </div>
      </template>
      <div v-else class="success-message">
        Your application successfully submitted!
      </div>
    </form>
    <div class="ellipse"></div>
    <div class="ellipse"></div>
  </div>
</template>

<script>
export default {
  components: {
  },
  async mounted() {
    await this.getCountries();
  },
  data: function () {
    return {
      formSubmitted: false,
      name: '',
      companyName: '',
      email: '',
      phone: '',
      industry: '',
      website: '',
      volume: '',
      country: '',
      countryId: '',
      comment: '',
      countryOptions: [],
      countryOptionsId: [],
      industryOptions: [
        "Account Support",
        "Trading and Transactions",
        "Security and Compliance",
        "Technical Support",
        "Customer Service",
        "Regulatory and Legal",
        "Partnerships and Business Inquiries",
        "Education and Resources",
        "Marketing and Promotions",
        "Media and Press",
        "Bug Reports",
        "Other"
      ],
      phoneFull: "",
      countryOptionsIso: []
    }
  },
  methods: {
    selectIndustry(event) {
      this.industry = event.text;
    },
    selectVolume(event) {
      this.volume = event.text;
    },
    selectCountry(event) {
      this.country = event.text;
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == event.text) {
          this.countryId = this.countryOptionsId[i];
        }
      }
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational;
    },
    getCountries() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
          this.countryOptionsIso = res.data.payload.map(obj => obj.iso);
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    submit() {
      this.$http.post(process.env.VUE_APP_API + 'contact-form', {
        name: this.name,
        company_name: this.companyName,
        email: this.email,
        phone: this.phoneFull,
        website: this.website,
        country: this.country.id,
        comment: this.comment
      })
          .then(({data}) => {
            if (data.status === 'OK') {
              this.formSubmitted = true
            }
          })
          .catch((res) => {
            if(res.response.status === 401) {
              this.$parent.openSignInModal();
            }
          })
    }
  }
}
</script>

<style scoped>

</style>