<template>
  <div class="modal register-modal">
    <div class="overlay" @click="$parent.closeRegister"></div>
    <div class="wrapper">
      <img
        class="close"
        @click="$parent.closeRegister"
        src="./../images/close.svg"
      />
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="title">Create account</div>
                <label>
                  <input
                    ref="birth_date"
                    @focus="showDatePicker"
                    @blur="hideDatePicker"
                    type="text"
                    :placeholder="'Date of Birth *'"
                    v-model="birth_date"
                    required
                  />
                </label>
                <label>
                  <!--                  <div class="desc">*First name</div>-->
                  <input
                    type="text"
                    placeholder="First name *"
                    v-model="name"
                  />
                </label>
                <label>
                  <!--                  <div class="desc">*Last Name</div>-->
                  <input
                    type="text"
                    placeholder="Last Name *"
                    v-model="surname"
                  />
                </label>
                <label>
                  <!--                  <div class="desc">*Email</div>-->
                  <input type="email" placeholder="Email *" v-model="email" />
                </label>
                <label v-if="countryOptionsIso && countryOptionsIso.length">
                  <VuePhoneNumberInput
                    v-model="phone"
                    :only-countries="countryOptionsIso"
                    :default-country-code="countryOptionsIso[0]"
                    @update="phoneDataUpdate($event)"
                  />
                </label>
                <label>
                  <!--                  <div class="desc">*Password</div>-->
                  <input
                    type="password"
                    placeholder="Password *"
                    v-model="pass"
                  />
                </label>
                <label>
                  <!--                  <div class="desc">*Confirm Password</div>-->
                  <input
                    type="password"
                    placeholder="Confirm Password *"
                    v-model="passConfirm"
                  />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`Address`) }}</div> -->
                  <input type="text" placeholder="Address" v-model="address" />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`Postal Code`) }}</div> -->
                  <input
                    type="text"
                    placeholder="Postal Code"
                    v-model="postCode"
                  />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`City`) }}</div> -->
                  <input type="text" placeholder="City" v-model="city" />
                </label>
                <label>
                  <!-- <div class="desc">{{ $t(`Country`) }}</div> -->
                  <Dropdown
                    :options="countryOptions"
                    value-key="id"
                    label-key="title"
                    placeholder="Country"
                    container-class="country-dropdown"
                    :container-style="{ width: '100%', marginTop: '12px' }"
                    :clearable="true"
                    @select="onCountrySelect"
                    @clear="onCountryClear"
                    :not-selected-option-style="{ color: 'rgb(117, 117, 117)' }"
                  />
                </label>
                <div class="link-container">
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input type="checkbox" name="terms" v-model="terms" />
                        <div class="checkbox"></div>
                        <span class="desc">I agree with </span>
                        <a @click="goToPage('privacy')" class="desc link">
                          {{ hasPrivacy || "Privacy Policy" }}
                        </a>
                        <span class="desc"> and </span>
                        <a @click="goToPage('terms')" class="desc link">
                          {{ hasTerms || "Terms of Service" }}
                        </a>
                      </div>
                    </label>
                  </div>
                </div>
                <button
                  :class="[
                    'button',
                    { disabled: !requiredRegisterFieldsAreFilled },
                  ]"
                  @click="submitRegister"
                >
                  <span>Create Account</span>
                </button>
                <div class="desc link" @click="$parent.openSignInModal">
                  Sign In!
                </div>
                <div v-if="$parent.error" class="desc red">
                  {{ $parent.error }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegisterModal",
  props: [],
  components: {},
  data: function () {
    return {
      email: "",
      name: "",
      surname: "",
      phone: "",
      pass: "",
      passConfirm: "",
      terms: false,
      phoneFull: "",
      countryOptions: [],
      countryOptionsIso: [],
      birth_date: null,

      address: "",
      postCode: "",
      city: "",
      country: "",
    };
  },
  mounted() {
    this.getCountries();
  },
  computed: {
    hasPrivacy() {
      if (this.$parent.textPageList && this.$parent.textPageList.length) {
        let result = this.$parent.textPageList.filter(
          (item) => item.is_privacy
        );
        return result && result.length ? result[0].title : false;
      }
      return false;
    },
    hasTerms() {
      if (this.$parent.textPageList && this.$parent.textPageList.length) {
        let result = this.$parent.textPageList.filter((item) => item.is_terms);
        return result && result.length ? result[0].title : false;
      }
      return false;
    },
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if (
        this.name &&
        this.surname &&
        this.phone &&
        this.email &&
        this.pass &&
        this.passConfirm &&
        this.terms &&
        this.birth_date
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    },
  },
  methods: {
    goToPage(type) {
      if (type === "privacy" && this.hasPrivacy) {
        this.$parent.goToPrivacy();
      } else if (type === "terms" && this.hasTerms) {
        this.$parent.goToTerms();
      } else {
        if (this.$route.path !== "/" && this.$route.path !== "/home") {
          this.$router.push("/");
        }
      }
    },
    onCountrySelect(country) {
      this.country = country;
    },
    onCountryClear() {
      this.country = "";
    },
    showDatePicker() {
      this.$refs.birth_date.type = "date";
      this.$refs.birth_date.focus();
    },
    hideDatePicker() {
      if (!this.birth_date) {
        this.$refs.birth_date.type = "text";
        this.birth_date = "";
      }
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational;
    },
    getCountries() {
      this.$http
        .get(process.env.VUE_APP_API + "countries")
        .then((res) => {
          this.countryOptions = res.data.payload;
          this.countryOptionsIso = res.data.payload.map((obj) => obj.iso);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    submitRegister() {
      let regData = {
        birth_date: this.birth_date,
        name: this.name,
        surname: this.surname,
        phone: this.phoneFull,
        email: this.email,
        password: this.pass,
        passConfirm: this.passConfirm,

        address: this.address,
        post_code: this.postCode,
        city: this.city,
        country_id: this.country.id,
      };
      this.$emit("registration", regData);
    },
  },
};
</script>
