import Vue from "vue";

import App from "./App.vue";
import router from "./router.js";
import "./moment";
import "./vee-validate";
import "./toast";
import axios from "axios";
Vue.prototype.$http = axios;

Vue.prototype.$http.interceptors.response.use(
  response => {
      // If the response was successful, just return the response
      return response;
  },
  error => {
      // If the response had an error, check the status code
      if (error.response.status === 401) {
        // If the status is 401 (Unauthorized), redirect to the Home page
        localStorage.setItem('isAuthenticated', false.toString());
        localStorage.setItem('emailVerified', false.toString());
        localStorage.removeItem('authToken');

        if (router.currentRoute.name != 'Home' && router.currentRoute.name != 'PasswordRestore') {
          router.push({ name: 'Home' });
        }
    }
      if (error.response.status === 403) {
        if (
          error.response.data.message ==
          "User identity not approved to submit orders"
        ) {
          router.push({ name: "Profile" });
        } else {
          localStorage.setItem("emailVerified", false.toString());
          router.push({ name: "EmailVerificationNotice" });
        }
      }
      return Promise.reject(error);
  }
);

Vue.prototype.$http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// import './event_bus'

global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;

import "./../node_modules/slick-carousel/slick/slick.css";
import "../public/css/style.scss";
import "promise-polyfill/src/polyfill";

Vue.prototype.$http.defaults.withCredentials = true;
Vue.prototype.$http.defaults.withXSRFToken = true;
Vue.prototype.$http.defaults.headers.common['X-XSRF-TOKEN'] = document.cookie.split('XSRF-TOKEN=')[1];

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);
import ArrowIcon from "./components/ArrowIcon.vue";
Vue.component('ArrowIcon', ArrowIcon)
import Dropdown from './components/Dropdown.vue'
Vue.component('Dropdown', Dropdown);


new Vue({
  router: router,
  render: (h) => h(App),
}).$mount("#app");
