import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
const IBAN = require('iban');

extend('iban', value => {
    if(IBAN.isValid(value)) {
        return true;
    }

    return 'The {_field_} field must be a valid IBAN.';
});

extend('bic', {
    validate: value => /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value),
    message: 'Invalid BIC format',
});

// Override the default message.
extend('required', required);
