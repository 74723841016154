<template>
  <div class="verification-notice">
    <h1>Email Verification Required</h1>
    <p class="email-desc">Please check your email for a verification link. You must verify your email before you can
      continue.</p>
    <button class="email-button" @click="resendVerificationEmail">Resend Verification Email</button>
    <button class="email-button" @click="$parent.logout">Logout</button>
  </div>
</template>
  
<script>
export default {
  name: 'EmailVerificationNotice',
  methods: {
    resendVerificationEmail() {
      this.$http.post(process.env.VUE_APP_API + 'email/verify/resend')
        .then((res) => {
          // handle success
          this.$toast.success(res.data.message)
          this.successMessage = 'Verification email sent!';
        })
        .catch(() => {
          // handle error
          this.$toast.error('Failed to send verification email')
          this.errorMessage = 'Failed to send verification email.';
        });
    }
  }
}
</script>

<style scoped>
.verification-notice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  min-height: 80vh;
  color: white;
  font-family: 'Open Sans', sans-serif;
  padding: 20px;
}

.email-button {
  display: flex;
  text-decoration: none;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #FFF;
}

.email-desc {
  line-height: 20px;
  text-align: center;
}
</style>
  