<template>
  <div v-bind="$attrs" v-on="$listeners" class="exchange-btn">
    <img class="arrows" src="../images/arrow-reload-horizontal-sharp.svg" />
  </div>
</template>

<script>

export default {
  name: 'ExchangeButton',
  props: [],
  data: function() {
    return {}
  },
  methods: {

  },
  computed: {},
  mounted() {}
}
</script>
