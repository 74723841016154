<template>
  <header class="header">
    <div class="wrapper">
      <div class="left">
        <router-link to="/" class="logo">
          <img src="./../images/favicon.png" class="img"/>
          <div class="title">Bitpooh</div>
        </router-link>
      </div>
      <div class="right">
        <router-link class="header-link exchange-link hover-link" to="/buy-crypto" >
          <img src="../images/exchange.svg" alt="">
          <span class="header-link__text">
            Exchange
          </span>
        </router-link>
        <router-link v-if="$parent.isAuth" class="header-link profile-link hover-link" to="/profile" >
          <img src="../images/user.svg" alt="">
          <span class="header-link__text">
            Profile
          </span>
        </router-link>
        <div v-else class="header-link profile-link hover-link" @click="$parent.openSignInModal" >
          <img src="../images/user.svg" alt="">
          <span class="header-link__text">
            Login
          </span>
        </div>
<!--        <div v-if="!$parent.isAuth" class="user hover-link" @click="$parent.openSignInModal">-->
<!--          <img src="./../images/user.svg" class="img"/>-->
<!--        </div>-->
<!--        <router-link v-if="$parent.isAuth" class="user hover-link" to="profile">-->
<!--          <img src="./../images/user.svg" class="img"/>-->
<!--        </router-link>-->
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: 'Header',
  props: [],
  data: function () {
    return {}
  },
  methods: {}
}
</script>
