<template>
  <main class="main profile-page page-inside">
    <div class="main-wrapper">
      <header-wrapper class="section hero-section">
        <div class="wrapper">
          <div class="title">
            Profile
          </div>
          <div v-if="status !== 'approved'" id="sumsub-websdk-container"></div>
          <div v-if="needsVerification" class="verification-container">
            <div class="success-request" v-if="verifcationSubmitSuccess">Your application has been successfully sent and
              will be reviewed within 1-2 business days!</div>
            <div class="title center">VERIFY YOUR ACCOUNT</div>
            <div class="select-wrapper">
              <div class="title small">Choose your document type</div>
              <select v-model="identityDocType" @change="handleDocTypeChange('identity', $event.target.value)">
                <option v-for="(type) in identityDocsTypes" :value="type" :key="type">
                  {{ type.charAt(0).toUpperCase() + type.slice(1) }}
                </option>
              </select>
            </div>
            <div class="img-upload-wrapper">
              <label v-for="([field, value], i) in identityDocs" :key="i" :class="['img-label', { 'active': image }]">
                <input type="file" @change="setImage('identity', field, $event)" />
                <img v-if="value && value.image" :src='value.image' class="img" />
                <div v-if="value && value.image && value.imageFile" class="desc desc-inside">
                  <div class="title small">Your file</div>
                  <div class="desc">{{ value.imageFile.name }}</div>
                </div>
                <img v-if="!value || !value.image" src="./../images/doc1.svg" class="img" />
                <div v-if="!value || !value.image" class="desc desc-inside">
                  <div class="title small">{{ fileDescription(identityDocuments, i) }}</div>
                  <div class="button">
                    <img src="./../images/down2.svg" class="img" />
                    <span>Upload</span>
                  </div>
                </div>
              </label>
            </div>
            <div class="submit-btn-wrapper">
              <button class="button" @click="sendToVerification">Send</button>
            </div>
            <div class="messages-wrapper">
              <div class="desc red" v-if="verificationRequestError">{{ verificationRequestError }}</div>
            </div>
          </div>
          <div class="status title">Status: {{ status }}</div>
          <div class="form">
            <div class="row">
              <div class="title small">Name</div>
              <label>
                <input type="text" placeholder="Name" v-model="name" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.name">{{ errors['name'][0] }}</div>
                </transition>
              </label>
              <label>
                <input type="text" placeholder="Last name" v-model="surname" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.surname">{{ errors['surname'][0] }}</div>
                </transition>
              </label>
            </div>
            <div class="row">
              <div class="title small">Email</div>
              <label>
                <input type="text" placeholder="Email" v-model="email" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.email">{{ errors['email'][0] }}</div>
                </transition>
              </label>
              <button class="button" @click="updateProfileData">
                <span>Update</span>
              </button>
              <!-- <transition name="fade">
                <div class="desc red" v-if="profileUpdateErrorMessaage">{{profileUpdateErrorMessaage}}</div>
              </transition>
              <transition name="fade">
                <div class="desc green" v-if="profileUpdateSuccessMessaage">{{profileUpdateSuccessMessaage}}</div>
              </transition> -->
            </div>

            <div class="row pass-row">
              <div class="title small">Additional Data</div>

              <label>
                <div class="desc">Address</div>
                <input type="text" placeholder='Address' v-model="address" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.address">{{ errors['address'][0] }}</div>
                </transition>
              </label>

              <label>
                <div class="desc">Postal Code</div>
                <input type="text" placeholder="Postal Code" v-model="postCode" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.post_code">{{ errors['post_code'][0] }}</div>
                </transition>
              </label>

              <label>
                <div class="desc">City</div>
                <input type="text" placeholder="City" v-model="city" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.city">{{ errors['city'][0] }}</div>
                </transition>
              </label>

              <label v-if="countryOptions.length > 1">
                <div class="desc">Country</div>
                <Dropdown :options="countryOptions" value-key="id" label-key="title" placeholder="Country"
                  :default-value="defaultCountry" container-class="country-dropdown"
                  :container-style="{ width: '100%' }" :clearable="true" @select="onCountrySelect"
                  :not-selected-option-style="{ color: '#a9a9a9' }" @clear="onCountryClear" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.country_id">{{ errors['country_id'][0] }}</div>
                </transition>
              </label>

              <label>
                <div class="desc">Phone</div>
                <VuePhoneNumberInput v-model="phone" :only-countries="countryOptionsIso"
                  :default-country-code="defaultCountryIso" @update="phoneDataUpdate($event)" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.phone">{{ errors['phone'][0] }}</div>
                </transition>
              </label>

              <button class="button" @click="updateProfileData">
                <span>Update</span>
              </button>
            </div>

            <div class="row pass-row">
              <div class="title small">Change Password</div>
              <label>
                <div class="desc">Enter current password:</div>
                <input type="password" placeholder="Current password" v-model="curPass" />
              </label>
              <label>
                <div class="desc">New Password:</div>
                <input type="password" placeholder="New password" v-model="newPass" />
              </label>
              <label>
                <div class="desc">Re-type New Password:</div>
                <input type="password" placeholder="Confirm password" v-model="repeatPass" />
              </label>
              <button class="button" @click="updatePassword">
                <span>Update</span>
              </button>
              <transition name="fade">
                <div class="desc red" v-if="passUpdateErrorMessaage">{{ passUpdateErrorMessaage }}</div>
              </transition>
              <transition name="fade">
                <div class="desc green" v-if="passUpdateSuccessMessaage">{{ passUpdateSuccessMessaage }}</div>
              </transition>
            </div>
            <div class="row logout-row">
              <div class="title small">Log Out</div>
              <button class="button" @click="$parent.logout">
                <span>Log Out</span>
              </button>
            </div>
            <div class="row">
              <div class="title small">Account Activity</div>
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <td class="title">ID</td>
                      <td class="title">Date</td>
                      <td class="title">Status</td>
                      <td class="title">Buy</td>
                      <td class="title">Pay</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in $parent.history" :key="i">
                      <td class="desc">{{ item.id }}</td>
                      <td class="desc">{{ $parent.formatDate(item.created_at) }}</td>
                      <td class="desc">{{ item.status }}</td>
                      <td class="desc">{{ item.buy }} {{ $parent.currency }}</td>
                      <td class="desc">{{ item.pay }} {{ $parent.currency }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </header-wrapper>
    </div>
  </main>
</template>
<script>
import snsWebSdk from '@sumsub/websdk';
import HeaderWrapper from "@/components/HeaderWrapper.vue";
import { serialize } from "object-to-formdata";

export default {
  name: 'Profile',
  props: [],
  components: {
    HeaderWrapper
  },
  data: function () {
    return {
      curPass: '',
      newPass: '',
      repeatPass: '',
      email: '',
      name: '',
      surname: '',
      phone: '',

      errors: {},
      timeoutId: null,

      address: null,
      postCode: null,
      city: null,
      country: null,
      phoneCountryCode: '',
      countryOptions: [],
      countryOptionsIso: [],

      // profileUpdateSuccessMessaage: '',
      // profileUpdateErrorMessaage: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      token: '',
      status: '',
      documentTypeOptions: [],
      identityDocType: '',
      identityDocuments: {},
      verificationRequestError: '',
      verificationRequestSuccess: '',
      verifcationSubmitSuccess: false,
      needsVerification: false
    }
  },
  watch: {

  },
  mounted() {
    this.getCountries();
    this.getDocTypes()
    this.getProfileData()

    this.$http.get(process.env.VUE_APP_API + 'sumsub-token')
      .then((res) => {
        this.token = res.data.token;
        this.launchWebSdk(this.token, this.email, this.phone)
      })
      .catch(() => { });
  },
  methods: {
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational || e.phoneNumber;
    },
    getCountries() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
        .then((res) => {
          this.countryOptions = res.data.payload
          this.countryOptionsIso = res.data.payload.map(obj => obj.iso)
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
    },
    onCountrySelect(country) {
      this.country = country;
    },
    onCountryClear() {
      this.country = null;
    },
    async sendToVerification() {
      const data = serialize({
        document_type: this.identityDocType,
      });

      Object.entries(this.identityDocuments).forEach(([name, { imageFile }]) => {
        data.append(name, imageFile);
      });

      this.$http.post(process.env.VUE_APP_API + 'user/verification', data, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        if (res.data.status === 'OK') {
          this.verifcationSubmitSuccess = true;
          setTimeout(() => {
            this.getProfileData();
          }, 1500);
        }
      }).catch((res) => {
        if (res.response.data.errors) {
          const firstErrors = {};
          for (const key in res.response.data.errors) {
            if (res.response.data.errors.hasOwnProperty(key) && Array.isArray(res.response.data.errors[key]) && res.response.data.errors[key].length > 0) {
              firstErrors[key] = res.response.data.errors[key][0];
              this.verificationRequestError = firstErrors[key];
            }
          }
        } else {
          this.verificationRequestError = res.response.data.message;
        }
        if (res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    async setImage(docType, doc, event) {
      const that = this;
      const propName = `${docType}Documents`;
      const documents = { ...this[propName] };
      const document = documents[doc];
      const selectedFile = event.target.files[0];
      document.imageFile = selectedFile;

      if (selectedFile) {
        document.image = URL.createObjectURL(selectedFile);
      } else {
        document.image = null;
      }

      documents[doc] = document;
      Object.assign(that, { [propName]: documents })
      console.log(that[propName]);
    },
    getProfileData() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
        .then((res) => {
          this.email = res.data.email;
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
          this.status = res.data.status;

          this.address = res.data.address;
          this.postCode = res.data.post_code;
          this.city = res.data.city;
          this.country = res.data.country;
          this.phoneCountryCode = res.data.phone_country_code;


          if (this.status != 'approved' && this.status != 'pending') {
            this.needsVerification = true;
          } else {
            this.needsVerification = false;
          }

        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
    },
    handleDocTypeChange(type, value) {
      let docTypes;
      if (type === 'identity') {
        docTypes = this.documentTypeOptions.identity_docs[value];
      }

      const documents = Object.fromEntries(docTypes.map((value) => ([value, {}])));
      console.log('doc change');
      if (type === 'identity') {
        this.identityDocuments = documents;
      }
    },
    fileDescription(docs, i) {
      const docsLength = Object.keys(docs).length;
      if (docsLength === 1 || docsLength > 2) {
        return 'Add your file';
      } else if (i === 0) {
        return 'Front side';
      } else {
        return 'Back side';
      }
    },
    launchWebSdk(accessToken, applicantEmail, applicantPhone) {
      let snsWebSdkInstance = snsWebSdk.init(
        accessToken,
        // token update callback, must return Promise
        // Access token expired
        // get a new one and pass it to the callback to re-initiate the WebSDK
        () => this.getNewAccessToken()
      )
        .withConf({
          levelName: 'Without KYC',
          lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
          email: applicantEmail,
          phone: applicantPhone,
          i18n: { "document": { "subTitles": { "IDENTITY": "Upload a document that proves your identity" } } },
          uiConf: {
            customCssStr: ":root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}"
          }
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        // see below what kind of messages WebSDK generates
        .on('idCheck.stepCompleted', (payload) => {
          console.log('stepCompleted', payload)
        })
        .on('idCheck.onError', (error) => {
          console.log('onError', error)
        })
        .build();

      // you are ready to go:
      // just launch the WebSDK by providing the container element for it
      snsWebSdkInstance.launch('#sumsub-websdk-container')
    },
    getNewAccessToken() {
      return Promise.resolve(this.token)// get a new token from your backend
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "surname": this.surname,
        "address": this.address,
        'city': this.city,
        'post_code': this.postCode,
        "country_id": this.country ? this.country?.id : null,
        "phone": this.phoneFull,
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
        .then((res) => {
          if (res.data.status == "OK") {
            this.errors = {}
            this.$toast.success('Profile successfully updated!');
            // this.profileUpdateSuccessMessaage = 'Success';
            // setTimeout(() => {
            //   this.profileUpdateSuccessMessaage = '';
            // }, 3000);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
          this.errors = res.response.data.errors;

          clearTimeout(this.timeoutId); // cancel previous timeout
          this.timeoutId = setTimeout(() => {
            // this.profileUpdateErrorMessaage = '';
            this.errors = {};
          }, 3000);

          // this.profileUpdateErrorMessaage = res.response.data.message;
          // setTimeout(() => {
          //   this.profileUpdateErrorMessaage = '';
          // }, 3000);
        })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
        .then((res) => {
          if (res.data.status == "OK") {
            this.passUpdateSuccessMessaage = 'Success';
            this.curPass = '',
              this.newPass = '',
              this.repeatPass = '';
            setTimeout(() => {
              this.passUpdateSuccessMessaage = '';
            }, 3000);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
          this.passUpdateErrorMessaage = res.response.data.message;
          setTimeout(() => {
            this.passUpdateErrorMessaage = '';
          }, 3000);
        })
    },
    getDocTypes() {
      this.$http.get(process.env.VUE_APP_API + 'doc-types')
        .then((res) => {
          this.documentTypeOptions = res.data.payload;
        })
        .catch(() => {

        })
    },
  },
  computed: {
    defaultCountryIso() {
      return this.phoneCountryCode || this.countryOptionsIso[0];
    },
    defaultCountry() {
      if (this.country && this.countryOptions.length > 0) {
        return this.countryOptions.find(option => option.id === this.country?.id) || this.countryOptions[0];
      } else {
        return null;
      }
    },
    identityDocsTypes() {
      return this.documentTypeOptions.identity_docs ? Object.keys(this.documentTypeOptions.identity_docs) : [];
    },
    identityDocs() {
      return Object.entries(this.identityDocuments);
    },
    // docs() {
    //   const that = this;
    //   return type => {
    //     that.
    //   }
    // }
  }
}
</script>