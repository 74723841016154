<template>
  <div class="reset-password-page">
    <div class="reset-password-section">
      <div class="wrapper">
        <div class="reset-password-wrapper">
          <div class="reset-password-form-wrapper">
            <h2 class="title">Reset Your Password</h2>
            <form @submit.prevent="PasswordRestore" class="reset-password-form">
              <div class="input-container">
                <label for="email" class="desc">Email</label>
                <input type="email" id="email" v-model="email" required />
              </div>
              <div class="input-container">
                <label for="password" class="desc">New Password</label>
                <input
                  type="password"
                  id="password"
                  v-model="password"
                  required
                />
              </div>
              <div class="input-container">
                <label for="password_confirmation" class="desc"
                  >Confirm New Password</label
                >
                <input
                  type="password"
                  id="password_confirmation"
                  v-model="password_confirmation"
                  required
                />
              </div>
              <button type="submit" class="button" :disabled="isLoading">
                {{ isLoading ? "Resetting..." : "Reset Password" }}
              </button>
              <p
                v-if="message"
                class="message"
                :class="{ error: status === 'ERROR' }"
              >
                {{ message }}
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordRestore",
  props: {
    isAuth: Boolean,
  },
  created() {
    // this.redirectIfAuth();
  },
  data() {
    return {
      email: "",
      password: "",
      password_confirmation: "",
      message: "",
      status: "",
      isLoading: false,
      errorTimer: null,
    };
  },
  mounted() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }
  },
  watch: {
    isAuth() {
      //   this.redirectIfAuth();
    },
  },
  methods: {
    // redirectIfAuth() {
      //   if (this.$parent.isAuth) {
      // this.$router.push({ path: "/" });
      //   }
    // },
    async PasswordRestore() {
      this.clearError();

      if (this.isLoading) return;

      this.isLoading = true;

      this.$http
        .post(process.env.VUE_APP_API + "user/password-reset", {
          token: this.$route.query.token || "",
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            this.$toast.success(response.data.message);
            this.$router.push({ path: "/" });
          } else {
            this.showError(response.data.message);
          }
        })

        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            const passwordErrors = error.response.data.errors.password;
            if (passwordErrors) {
              this.showError(passwordErrors[0]);
            }
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            this.showError(error.response.data.message);
          } else {
            this.showError("An error occurred during password reset.");
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showError(errorMessage) {
      this.status = "ERROR";
      this.message = errorMessage;

      // Clear any existing timer
      if (this.errorTimer) {
        clearTimeout(this.errorTimer);
      }

      // Set a new timer to clear the error after 2 seconds
      this.errorTimer = setTimeout(() => {
        this.clearError();
      }, 6000);
    },
    clearError() {
      this.status = "";
      this.message = "";
      if (this.errorTimer) {
        clearTimeout(this.errorTimer);
        this.errorTimer = null;
      }
    },
  },
  beforeUnmount() {
    // Clear the timer if the component is destroyed
    if (this.errorTimer) {
      clearTimeout(this.errorTimer);
    }
  },
};
</script>

<style scoped lang="scss">
.reset-password-page {
  .reset-password-section {
    padding-top: 0;
    text-align: center;

    .wrapper {
      padding: 80px 0;
    }
  }

  .reset-password-wrapper {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    border-radius: 6px;
    overflow: hidden;
    padding: 40px;

    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .reset-password-form-wrapper {
    text-align: center;
    max-width: 100%;
    margin: 0 auto;

    .title {
      margin-bottom: 28px;
      padding: 0;
      font-size: 24px;
      line-height: 32px;
      color: #000;
    }
  }

  .reset-password-form {
    font-family: inherit;

    .input-container {
      width: 100%;
      margin-bottom: 16px;
      text-align: left;

      .desc {
        padding-bottom: 8px;
        font-size: 14px;
        display: block;
      }

      input {
        font-family: inherit;
      }
    }

    .message {
      margin-top: 16px;
      font-size: 14px;
      line-height: 19px;
      color: #fca330;

      &.error {
        color: #ff4766;
      }
    }
  }
}
</style>
