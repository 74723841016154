<template>
  <main class="main page-inside payment-result-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title white big">Your order is currently processing, please wait!</div>
          <div class="loader" style="margin-top: 30px;"></div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'PaymentSuccessPage',
  props: [],
  components: {},
  mounted() {
    this.checkStatus();
    this.interval = setInterval(this.checkStatus, 10000);
  },
  data: function () {
    return {
      interval: null
    }
  },
  methods: {
    checkStatus() {
      this.$http.get(process.env.VUE_APP_API + 'orders/status', {
        params: {
          order_id: this.$route.query.order_id
        }
      })
          .then((res) => {
            if (res.data.status == "OK") {
              if (res.data.order.redirect_url) {
                window.location = res.data.order.redirect_url;
              }

              if (res.data.order.status !== 'pending') {
                if (res.data.order.status == 'paid') {
                  this.$router.push('/success-payment')
                } else if (res.data.order.status == 'error') {
                  this.$router.push('/fail-payment')
                }
                clearInterval(this.interval);
              }
            } else {
              clearInterval(this.interval);
            }
          })
          .catch((res) => {
            console.log(res);
          })
    },
  }
}
</script>