<template>
    <div class="select-container" :class="containerClass" :style="containerStyle" @click="toggleDropdown"
        @keydown="handleKeydown" tabindex="0">
        <div class="dropdown" @blur="isOpen = false">
            <div class="dropdown-selected" ref="dropdownSelected" :style="customStyle">
                {{ (selectedOption && selectedOption[labelKey]) ? selectedOption[labelKey] : placeholder }}
                <div class="dropdown-actions">
                    <span class="dropdown-arrow" :class="{ 'arrow-up': isOpen }">▾</span>
                    <span v-if="clearable && selectedOption" class="dropdown-clear"
                        @click.stop="clearSelection">×</span>
                </div>
            </div>
            <transition name="fade">
                <div v-if="isOpen" class="dropdown-menu">
                    <div v-for="(option, index) in options" :key="option[valueKey]" tabindex="0" class="dropdown-item"
                        :class="{ 'focused': focusedOptionIndex === index }" @click.stop="selectOption(option)"
                        @mouseover="focusedOptionIndex = index" @focus="focusedOptionIndex = index">
                        {{ option[labelKey] }}
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: { type: Array, required: true },
        valueKey: { type: String, default: 'id' },
        labelKey: { type: String, default: 'title' },
        placeholder: { type: String, default: 'Select an option' },
        defaultValue: { type: [Number, String, Object], default: null },
        containerClass: { type: String, default: '' },
        containerStyle: { type: Object, default: () => ({}) },
        clearable: { type: Boolean, default: false },
        notSelectedOptionStyle: { type: Object, default: () => ({}) },
    },
    data() {
        return {
            isOpen: false,
            selectedOption: null,
            focusedOptionIndex: -1,
        };
    },
    computed: {
        customStyle() {
            if (this.notSelectedOptionStyle && (this.selectedOption == '' || this.selectedOption === null)) {
                return this.notSelectedOptionStyle;
            }
            return null;
        },
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
            if (this.isOpen) {
                this.focusedOptionIndex = 0;
                this.$nextTick(() => this.scrollToOption(this.focusedOptionIndex));
            } else {
                this.focusedOptionIndex = -1;
            }
        },
        selectOption(option) {
            this.selectedOption = option;
            this.isOpen = false;
            this.$emit('select', option);
        },
        clearSelection() {
            this.selectedOption = null;
            this.isOpen = false;
            this.$emit('clear');
        },
        closeDropdown(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false;
                this.focusedOptionIndex = -1;
            }
        },
        handleKeydown(event) {
            if (!this.isOpen && event.key === 'Enter') {
                event.preventDefault();
                this.toggleDropdown();
            } else if (this.isOpen) {
                if (event.key === 'ArrowDown') {
                    event.preventDefault();
                    this.focusedOptionIndex = (this.focusedOptionIndex + 1) % this.options.length;
                    this.scrollToOption(this.focusedOptionIndex);
                } else if (event.key === 'ArrowUp') {
                    event.preventDefault();
                    this.focusedOptionIndex = (this.focusedOptionIndex - 1 + this.options.length) % this.options.length;
                    this.scrollToOption(this.focusedOptionIndex);
                } else if (event.key === 'Tab') {
                    if (event.shiftKey) {
                        this.focusedOptionIndex = (this.focusedOptionIndex - 1 + this.options.length) % this.options.length;
                    } else {
                        this.focusedOptionIndex = (this.focusedOptionIndex + 1) % this.options.length;
                    }
                    this.scrollToOption(this.focusedOptionIndex);
                    event.preventDefault(); // Prevent the default tab behavior
                } else if (event.key === 'Enter') {
                    event.preventDefault();
                    if (this.focusedOptionIndex !== -1) {
                        this.selectOption(this.options[this.focusedOptionIndex]);
                    }
                }
            }
        },
        scrollToOption(index) {
            this.$nextTick(() => {
                const dropdownMenu = this.$el.querySelector('.dropdown-menu');
                const optionElement = dropdownMenu.children[index];
                if (optionElement) {
                    dropdownMenu.scrollTop = optionElement.offsetTop;
                }
            });
        },
    },
    watch: {
        defaultValue(newValue) {
            if (typeof newValue === 'number' || typeof newValue === 'string') {
                this.selectedOption = this.options.find(option => option[this.valueKey] == newValue);
            } else {
                this.selectedOption = newValue;
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.closeDropdown);
        if (this.defaultValue) {
            if (typeof this.defaultValue === 'number' || typeof this.defaultValue === 'string') {
                this.selectedOption = this.options.find(option => option[this.valueKey] == this.defaultValue);
            } else {
                this.selectedOption = this.defaultValue;
            }
        }
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeDropdown);
    },
};
</script>
<style scoped>
.select-container {
    position: relative;
    display: inline-block;
    width: 200px;
    border-radius: 16px;
    box-shadow: inset 0px 0px 12px #8A97B114;
    background: #F2F5FB;
    font-family: 'Open Sans', sans-serif;
    border: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    outline: 0;
    padding: 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
        border: 1px solid #272928;
    }
}

.dropdown {
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
}

.dropdown-selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 20px;
}

.dropdown-arrow {
    margin-left: 8px;
    transition: transform 0.3s;
}

.arrow-up {
    transform: rotate(180deg);
}

.dropdown-clear {
    margin-left: 8px;
    color: inherit;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 3;
    max-height: 200px;
    overflow-y: auto;
}

.dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown-item:hover,
.dropdown-item.focused {
    background-color: #f5f5f5;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>