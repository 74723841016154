<template>
  <div class="section section-7">
    <div class="wrapper">
      <div class="top">
        <div class="title big">FAQ's</div>
      </div>
      <div class="accordion">
        <div class="item" v-for="(item, index) in faq" :key="index">
          <div class="faq-question" @click="toggleAnswer(index)">
            <div
              :class="['title small left', { active: activeIndex === index }]"
            >
              {{ item.question }}
            </div>
            <ArrowIcon color="white"/>
          </div>
          <transition name="fade">
            <div
              v-html="item.answer"
              v-if="activeIndex === index"
              class="desc faq-answer"
            ></div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "FaqSection",
  props: {
    faq: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
  },
  data() {
    return {
      activeIndex: null,
    };
  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
