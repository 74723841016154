<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <path
      id="Контур_12581"
      data-name="Контур 12581"
      d="M-8111.586,7.567a1.147,1.147,0,0,1-.817-.334c-.036-.036-.069-.074-4.847-4.863a1.146,1.146,0,0,1,0-1.62,1.145,1.145,0,0,1,1.621,0l4.043,4.045,4.043-4.045a1.146,1.146,0,0,1,1.622,0,1.148,1.148,0,0,1,0,1.62c-4.778,4.788-4.812,4.826-4.848,4.863a1.143,1.143,0,0,1-.81.334Z"
      transform="translate(8117.585 -0.414)"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowIcon",
  props: {
    width: {
      type: [Number, String],
      default: 12,
    },
    height: {
      type: [Number, String],
      default: 7.153,
    },
    color: {
      type: String,
      default: "#7f8798",
    },
    rotation: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    viewBox() {
      return `0 0 ${this.width} ${this.height}`;
    },
  },
};
</script>
