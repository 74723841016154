<template>
  <main class="main page-inside faq-page">
    <div class="main-wrapper">
      <FaqSection v-if="$parent.faq && $parent.faq.length" :faq="$parent.faq" />
    </div>
  </main>
</template>
<script>
import FaqSection from "../components/FaqSection.vue";

export default {
  name: "Faq",
  props: [],
  components: {
    FaqSection,
  },
  data: function () {
    return {
      // activePanels: [],
    };
  },
  methods: {
    // toggleAnswer(index) {
    //   if (this.isActiveAnswer(index)) {
    //     this.activePanels.splice(this.activePanels.indexOf(index), 1);
    //   } else {
    //     this.activePanels.push(index);
    //   }
    // },
    // isActiveAnswer(index) {
    //   return this.activePanels.includes(index);
    // },
  },
};
</script>
