<template>
  <div class="verification-notice">
    <h1>Email Verification Success</h1>
    <p class="email-desc">Your email has been successfully verified. Thank you!</p>
  </div>
</template>
  
<script>
export default {
  name: 'EmailVerificationSuccess',
  // ...
}
</script>
  
<style scoped>
.verification-notice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  min-height: 80vh;
  color: white;
  font-family: 'Open Sans', sans-serif;
}
.email-desc {
  line-height: 20px;
  text-align: center;
}
</style>