<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="content">
            <div class="card-list">
              <div class="card-list__item order-details">
                <div class="title small">Confirmation</div>
                <div class="desc">Order has been placed</div>
                <div class="desc">Your order <b>#32437535</b> has been successfully placed! See information below</div>
                <div class="total">
                  <div class="desc">Order number <b>#32437535</b></div>
                  <div class="total-table">
                    <div class="item">
                      <div class="desc">Order status</div>
                      <div class="desc">Awaiting Payment</div>
                    </div>
                    <div class="item">
                      <div class="desc">Date of order placed</div>
                      <div class="desc">07 Mar 2022, 18:02</div>
                    </div>
                    <div class="item">
                      <div class="desc">Exchange rate</div>
                      <div class="desc">1 BTC = 38554.99 EUR</div>
                    </div>
                    <div class="item">
                      <div class="desc">Processing fee</div>
                      <div class="desc">20.00 EUR</div>
                    </div>
                    <div class="item">
                      <div class="desc">Total received</div>
                      <div class="desc">00000000000000</div>
                    </div>
                    <div class="item">
                      <div class="desc">Total amount spent</div>
                      <div class="desc">0,444454564 BTC</div>
                    </div>
                  </div>
                </div>
                <div class="total-bottom">
                  <router-link to="profile" class="button">Done</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'OrderDetails',
  props: [],
  components: {
  },
  data: function() {
    return {
      paymentMethod: 'visaMastercard',
      wallet: ''
    }
  },
  methods: {
   
  }
}
</script>