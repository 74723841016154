<template>
  <div class="crypto-section">
    <div class="input-container">
      <input type="number" :value="takeAmount" @input="$emit('update:take-amount',  $event.target.value); changeGiveAmount($event.target.value);"/>
      <div class="desc">{{takeCurrency.crypto_currency}}</div>
    </div>
    <div class="radio-list cryptocurrency-list">
      <div class="list" v-if="rates && giveCurrency.title == 'Euro'">
        <div class="desc title">Assets:</div>
        <div class="item" v-for="item in filteredRatesEur" :key="item.id">
          <label>
            <input type="radio" :checked="takeCurrency.crypto_currency === item.crypto_currency"  @change="$emit('update:take-currency', item)" :value="item.crypto_currency" />
            <div class="text">
              <div class="desc">{{item.crypto_currency}}</div>
              <div class="desc">{{item.price}} : 1</div>
            </div>
          </label>
        </div>
      </div>
      <div class="list" v-if="rates && giveCurrency.title == 'US dollar'">
        <div class="item" v-for="item in filteredRatesUsd" :key="item.id">
          <label>
            <input type="radio" :checked="takeCurrency.crypto_currency === item.crypto_currency"  @change="$emit('update:take-currency', item)" :value="item.crypto_currency" />
            <div class="text">
              <div class="desc">{{item.crypto_currency}}</div>
              <div class="desc">{{item.price}} : 1</div>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CryptoSection',
  props: {
    giveCurrency : {
      type: Object
    },
    rates: {
      type: Array
    },
    filteredRatesEur: {
      type: Array
    },
    filteredRatesUsd: {
      type: Array
    },
    takeCurrency: {
      type: Object
    },
    takeAmount: {
      type: Number
    },
    changeGiveAmount: {
      type: Function
    },
    toggleFiatToCrypto: {
      type: Function
    },
    paymentMethod: {
      type: String
    }
  },
  data: function() {
    return {}
  },
  methods: {

  },
  computed: {},
  mounted() {}
}
</script>
